import { DynamicFormConfigSurvey, DynamicFormElementType, DynamicFormElementWidthMode } from '@app/dynamic';
import { EmbryoTransferTypeEnum } from '@app/application/constants';
import { yup } from '@app/formik';
import {
  ContactFrequencyEnum,
  EggProviderEnum,
  EmbryosStatusEnum,
  QuestionnairePdf,
  QuestionnaireSurveyEnum,
  ReduceBooleanEnum,
  SpermProviderEnum,
} from '../constants';
import cardImage from '../images/card-questionnaire-surrogacy-journey.jpg';

export const surrogacyJourney: DynamicFormConfigSurvey<QuestionnaireSurveyEnum> = {
  name: QuestionnaireSurveyEnum.surrogacyJourney,
  meta: {
    cardImage,
    dependOnFields: ['status', 'isPartnerInformation'],
  },
  pages: [
    {
      name: `${QuestionnaireSurveyEnum.surrogacyJourney}.information`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.surrogacyJourney}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            variables: {
              time: '15-20',
            },
            translation: 'questionnaire',
            template: [['description.item1', 'description.item2', 'description.item3']],
          },
        },
      ],
    },
    {
      name: `${QuestionnaireSurveyEnum.surrogacyJourney}.information.part1`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.surrogacyJourney}.information.part1`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            translation: 'questionnaire',
            template: ['header'],
          },
        },
      ],
    },
    {
      name: 'haveMatchedWithSurrogate',
      elements: [
        {
          noLabel: true,
          name: 'haveMatchedWithSurrogate',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {},
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'matchWithSurrogateDescription',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ haveMatchedWithSurrogate }) => Boolean(haveMatchedWithSurrogate),
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isPlanningToTandem',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'isPlanningToTandem',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {},
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'tandemDescription',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ isPlanningToTandem }) => Boolean(isPlanningToTandem),
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isWorkingWithIVF',
      elements: [
        {
          noLabel: true,
          name: 'isWorkingWithIVF',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {},
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'clinicName',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ isWorkingWithIVF }) => Boolean(isWorkingWithIVF),
          props: {},
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'clinicLocation',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ isWorkingWithIVF }) => Boolean(isWorkingWithIVF),
          props: {
            length: 100,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'doctorName',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ isWorkingWithIVF }) => Boolean(isWorkingWithIVF),
          props: {
            length: 100,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'clinicCoordinatorName',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ isWorkingWithIVF }) => Boolean(isWorkingWithIVF),
          props: {
            length: 100,
            withLockIcon: true,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
        },
        {
          name: 'clinicContactEmail',
          alias: 'clinicContactEmail',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ isWorkingWithIVF }) => Boolean(isWorkingWithIVF),
          validIf: value => yup.string().email().isValidSync(value),
          validation: (value: any) => {
            try {
              yup.string().email().isValidSync(value);
              return undefined;
            } catch (error) {
              return (error as Error).message;
            }
          },
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
          props: {
            withLockIcon: true,
          },
        },
      ],
    },
    {
      name: 'embryos',
      elements: [
        {
          name: 'embryosStatus',
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            enum: EmbryosStatusEnum,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'embryosStatusDescription',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ embryosStatus }) => embryosStatus !== EmbryosStatusEnum.ready,
          props: {
            multiline: true,
            length: 1000,
          },
        },
        {
          name: 'numberOfEmbryos',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ embryosStatus }) => embryosStatus === EmbryosStatusEnum.ready,
          validIf: value => Number(value) > 0,
          validation: (value: any) => {
            try {
              yup.number().min(1).validateSync(value);
              return undefined;
            } catch (error) {
              return (error as Error).message;
            }
          },
          props: {
            number: true,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'areGeneticallyTested',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ embryosStatus }) => embryosStatus === EmbryosStatusEnum.ready,
          showDescription: true,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'spermProvider',
      elements: [
        {
          noLabel: true,
          name: 'spermProvider',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            enum: SpermProviderEnum,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'spermProvidersNames',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ spermProvider }) => spermProvider === SpermProviderEnum.intendedParent,
          props: {
            length: 100,
          },
        },
      ],
    },
    {
      name: 'eggProvider',
      elements: [
        {
          noLabel: true,
          name: 'eggProvider',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            enum: EggProviderEnum,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'eggProvidersNames',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ eggProvider }) => eggProvider === EggProviderEnum.intendedParent,
          props: {
            length: 100,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'transferringDetails',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'transferringDetails',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 500,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'embryoTransferType',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'embryoTransferType',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            enum: EmbryoTransferTypeEnum,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'numberOfCycles',
      elements: [
        {
          noLabel: true,
          name: 'numberOfCycles',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isPlanningToMoreEmbryos',
      elements: [
        {
          noLabel: true,
          name: 'isPlanningToMoreEmbryos',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isContinueTwinPregnancy',
      elements: [
        {
          noLabel: true,
          name: 'isContinueTwinPregnancy',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isReduceTriplets',
      elements: [
        {
          noLabel: true,
          name: 'isReduceTriplets',
          alias: 'reduceBoolean',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            enum: ReduceBooleanEnum,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isReduceTwins',
      elements: [
        {
          noLabel: true,
          name: 'isReduceTwins',
          alias: 'reduceBoolean2',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            enum: ReduceBooleanEnum,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isAmniocentesisWant',
      elements: [
        {
          noLabel: true,
          name: 'isAmniocentesisWant',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isConsiderTerminationMedical',
      elements: [
        {
          noLabel: true,
          name: 'isConsiderTerminationMedical',
          alias: 'reduceBoolean3',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            enum: ReduceBooleanEnum,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'medicalTerminationDescription',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ isConsiderTerminationMedical }) =>
            isConsiderTerminationMedical === ReduceBooleanEnum.medicalReason,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isConsiderTerminationGender',
      elements: [
        {
          noLabel: true,
          name: 'isConsiderTerminationGender',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isConsiderTerminationDownSyndron',
      elements: [
        {
          noLabel: true,
          name: 'isConsiderTerminationDownSyndron',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: `${QuestionnaireSurveyEnum.surrogacyJourney}.information.part2`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.surrogacyJourney}.information.part2`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            translation: 'questionnaire',
            template: ['header'],
          },
        },
      ],
    },
    {
      name: 'relationshipWithSurrogate',
      elements: [
        {
          noLabel: true,
          name: 'relationshipWithSurrogate',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'frequencyOfCommunication',
      elements: [
        {
          name: 'textMessagingFrequency',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            enum: ContactFrequencyEnum,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'emailFrequency',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            enum: ContactFrequencyEnum,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'inPersonFrequency',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            enum: ContactFrequencyEnum,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'faceTimeFrequency',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            enum: ContactFrequencyEnum,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'birthPresent',
      elements: [
        {
          noLabel: true,
          name: 'birthPresent',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'birthVideosPictures',
      elements: [
        {
          noLabel: true,
          name: 'birthVideosPictures',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            length: 500,
            multiline: true,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isPumpBreastMilk',
      elements: [
        {
          noLabel: true,
          name: 'isPumpBreastMilk',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'pumpBreastMilkDuration',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ isPumpBreastMilk }) => Boolean(isPumpBreastMilk),
          props: {
            multiline: true,
            length: 500,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'futureContact',
      elements: [
        {
          noLabel: true,
          name: 'futureContact',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'letterToSurrogate',
      elements: [
        {
          name: 'letterToSurrogate.information',
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            pageInner: true,
            template: ['text'],
            translation: 'questionnaire',
          },
        },
        {
          noLabel: true,
          name: 'letterToSurrogate',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            rows: 6,
            length: 2000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'avatar.name',
          alias: 'avatar',
          showDescription: true,
          type: DynamicFormElementType.upload,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            cropperProps: {
              cropShape: 'round',
            },
            type: 'questionnaireAvatar',
          },
        },
      ],
    },
    {
      name: 'emergencyContact',
      showDescription: true,
      elements: [
        {
          name: 'emergencyContactFirstName',
          alias: 'firstName',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            withLockIcon: true,
            length: 50,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
        },
        {
          name: 'emergencyContactLastName',
          alias: 'lastName',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            withLockIcon: true,
            length: 50,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
        },
        {
          name: 'emergencyContactPhone',
          type: DynamicFormElementType.phone,
          widthMode: DynamicFormElementWidthMode.full,
          showDescription: true,
          props: {
            disableDropdown: false,
            forceCallingCode: false,
            withLockIcon: true,
            length: 13,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
        },
      ],
    },
  ],
};

import { Questionnaire } from '@app/questionnaire/types/Questionnaire';
import { Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import { palette } from '@app/app/configs/theme';
import { useTranslation } from 'react-i18next';
import { mainStyles } from '@app/application/components/pdf/styles';
import { isAnswered } from '@app/dynamic/utils';
import { COUNTRIES } from '@app/application/constants/countries';
import logoSmallWhite from '../images/logo-main.png';

export interface QuestionnairePdfCoverPageProps {
  readonly data: Partial<Questionnaire>;
}

export const questionnaireGetName = ({ firstName, partner }: Partial<Questionnaire>): string => {
  if (!firstName) {
    return '';
  }

  if (!partner || !partner.firstName) {
    return firstName;
  }

  return `${firstName} and ${partner.firstName}`.length < 18
    ? `${firstName} and ${partner.firstName}`
    : `${firstName} and\n${partner.firstName}`;
};

export const QuestionnairePdfCoverPage: React.FC<QuestionnairePdfCoverPageProps> = ({ data }) => {
  const { t } = useTranslation('common');
  const { highlights } = data;

  return (
    <Page style={[mainStyles.page, styles.page]}>
      <View style={[mainStyles.header, styles.header]}>
        <View style={styles.headerBlock}>
          <View style={styles.photoBlock}>
            <View style={styles.photoBackground} />
            {data?.avatar?.download?.file && (
              <View style={styles.avatar}>
                <Image style={styles.avatarImage} src={data.avatar.download.file} />
              </View>
            )}
          </View>
        </View>
        <View style={styles.profileInfo}>
          <View>
            <Text style={styles.subject}>{t('questionnaire.pdf.headerSubject')}:</Text>
            <View style={{ display: 'flex', width: 250, flexDirection: 'row' }}>
              <Text style={styles.name}>{questionnaireGetName(data)}</Text>
            </View>
          </View>
          <View style={styles.aboutNotes}>
            <Text style={styles.aboutNotesText}>{t('questionnaire.pdf.aboutNotes')}</Text>
          </View>
        </View>
      </View>
      <View style={[mainStyles.content, styles.content]}>
        <Text style={[mainStyles.h1, styles.pageTitle]}>{t('questionnaire.pdf.coverTitle', data)}</Text>
        <View style={styles.contentCard}>
          {highlights && Object.keys(highlights).length !== 0 && (
            <View style={styles.contentCardItem}>
              <View wrap={false} style={[styles.contentItem, { marginTop: 0 }]}>
                <Text style={[mainStyles.label, styles.label]}>
                  {t(`questionnaire.field.location.label`, t(`questionnaire.field.location.subtitle`))}:
                </Text>
                <Text style={mainStyles.text}>
                  {COUNTRIES.find(({ id }) => id === highlights.country)?.name}
                  {highlights.city ? `, ${highlights.city}` : ''}
                </Text>
              </View>
              <View wrap={false} style={styles.contentItem}>
                <Text style={[mainStyles.label, styles.label]}>
                  {t(
                    `questionnaire.field.firstParentAge.label`,
                    t(`questionnaire.field.firstParentAge.subtitle`),
                    data,
                  )}
                  :
                </Text>
                <Text style={mainStyles.text}>{highlights.firstParentAge}</Text>
              </View>
              {data?.partner && (
                <View wrap={false} style={styles.contentItem}>
                  <Text style={[mainStyles.label, styles.label]}>
                    {t(
                      `questionnaire.field.secondParentAge.label`,
                      t(`questionnaire.field.secondParentAge.subtitle`),
                      data,
                    )}
                    :
                  </Text>
                  <Text style={mainStyles.text}>{highlights.secondParentAge}</Text>
                </View>
              )}
              <View wrap={false} style={styles.contentItem}>
                <Text style={[mainStyles.label, styles.label]}>
                  {t(`questionnaire.field.embryo.label`, t(`questionnaire.field.embryo.subtitle`))}:
                </Text>
                <Text style={mainStyles.text}>
                  {highlights.numberOfEmbryos},{' '}
                  {t(`questionnaire.field.embryosStatus.option.${highlights.embryoStatus}`)}
                </Text>
              </View>
              <View wrap={false} style={styles.contentItem}>
                <Text style={[mainStyles.label, styles.label]}>
                  {t(`questionnaire.field.clinic.label`, t(`questionnaire.field.clinic.subtitle`))}:
                </Text>
                <Text style={mainStyles.text}>
                  {highlights.clinicName}, {highlights.clinicLocation}
                </Text>
              </View>
              {isAnswered(highlights.about) && (
                <View wrap={false} style={styles.contentItem}>
                  <Text style={[mainStyles.label, styles.label]}>
                    {t(`questionnaire.field.about.label`, t(`questionnaire.field.about.subtitle`))}:
                  </Text>
                  <Text style={mainStyles.text}>{highlights.about}</Text>
                </View>
              )}
              {isAnswered(highlights.additionalNotes) && (
                <View wrap={false} style={styles.contentItem}>
                  <Text style={[mainStyles.label, styles.label]}>
                    {t(`questionnaire.field.additionalNotes.label`, t(`questionnaire.field.additionalNotes.subtitle`))}:
                  </Text>
                  <Text style={mainStyles.text}>{highlights.additionalNotes}</Text>
                </View>
              )}
            </View>
          )}
        </View>
      </View>
      <View fixed style={[mainStyles.footer, styles.footer]}>
        <View>
          <Text style={[mainStyles.h3, styles.footerTitle]}>{t('questionnaire.pdf.title')}</Text>
          <Text>{t('questionnaire.pdf.description')}</Text>
        </View>
        <Image style={styles.footerLogo} src={logoSmallWhite} />
      </View>
    </Page>
  );
};

const styles = StyleSheet.create({
  page: {
    paddingBottom: 74,
    backgroundColor: palette.secondary.main,
  },
  header: {
    position: 'static',
    height: 310,
    marginLeft: 30,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FFF',
    paddingHorizontal: 0,
    marginBottom: -1,
  },
  headerBlock: {
    paddingTop: 55,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: palette.secondary.main,
  },
  photoBlock: {
    backgroundColor: '#FFF',
    position: 'relative',
  },
  photoBackground: {
    backgroundColor: palette.secondary.main,
    width: '50%',
    height: '100%',
    position: 'absolute',
  },
  avatar: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  avatarImage: {
    objectFit: 'cover',
    objectPosition: 'center',
    height: '100%',
    width: '100%',
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  profileInfo: {
    paddingLeft: 30,
    paddingTop: 55,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
  },
  subject: {
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 12,
  },
  name: {
    color: palette.secondary.main,
    fontSize: 20,
    fontWeight: 600,
    maxWidth: '80%',
    flex: 1,
  },
  aboutNotes: {
    marginTop: 'auto',
    color: palette.text.secondary,
    paddingTop: 12,
    borderTop: `0.5pt solid ${palette.grey[500]}`,
  },
  aboutNotesText: {
    maxWidth: '80%',
    fontSize: 6,
  },
  content: {
    marginLeft: 30,
    paddingTop: 10,
    backgroundColor: '#FFF',
    borderBottomLeftRadius: 10,
  },
  pageTitle: {
    fontSize: 10,
    textTransform: 'uppercase',
    marginBottom: 0,
    marginTop: 25,
  },
  contentCard: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentCardItem: {
    borderRadius: 10,
    paddingTop: 25,
    paddingBottom: 30,
    width: '100%',
  },
  contentCardItemAbout: {
    backgroundColor: 'transparent',
    paddingLeft: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  contentItem: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'row',
  },
  label: { width: '30%', color: palette.secondary.main, fontWeight: 700 },
  aboutTitle: {
    marginBottom: 7,
  },
  footer: {
    backgroundColor: palette.secondary.main,
    color: '#FFF',
    height: 74,
    paddingVertical: 14,
  },
  footerTitle: {
    marginBottom: 9,
  },
  footerLogo: {
    width: 25,
  },
});

import { useField } from 'formik';
import { memo, useCallback } from 'react';
import { Text as MuiText, TextProps as MuiTextProps } from '@app/ui/forms/components/Text';

import { useErrorTranslations } from '../../hooks/useErrorTranslations';

export type TextProps = MuiTextProps & {
  readonly name: string;
  readonly withLockIcon?: boolean;
  readonly validation?: (value: any) => string | undefined;
};

export const Text = memo(({ name, helperText, ...props }: TextProps) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField({
    name,
    ...(props.validation && { validate: props.validation }),
  });

  const errorHelperText = useErrorTranslations(name, error);

  const isError = Boolean(touched && errorHelperText);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  return (
    <MuiText
      {...props}
      value={value}
      name={name}
      onChange={handleChange}
      onBlurCapture={() => setTouched(true)}
      error={isError}
      helperText={isError ? errorHelperText : helperText || ''}
    />
  );
});

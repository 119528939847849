import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { ApolloError, useMutation, useQuery } from '@apollo/client';

import { omitDeep } from '@app/app/utils/omitDeep';
import { extractValidationErrors } from '@app/query';
import { error } from '@app/snackbars';
import { DynamicFormConfigSurvey } from '@app/dynamic';
import { isVisible } from '@app/dynamic/utils';

import { Application } from '../types/Application';
import { getFieldsFromSurveys, prepareAdminConfig } from '../utils/survey';
import { applicationGetOne, applicationUpdateOne } from '../gql';

export interface UseUpdateApplication {
  readonly id: number;
  readonly config?: DynamicFormConfigSurvey;
  readonly onUpdateSuccess?: () => void;
  readonly isAdmin?: boolean;
  readonly withFlags?: boolean;
}

export const useUpdateApplication = ({
  id,
  config,
  onUpdateSuccess,
  withFlags = false,
  isAdmin = false,
}: UseUpdateApplication) => {
  const { t } = useTranslation('common');

  const { surveyConfig, excludedFields, applicationGetOneGql, applicationUpdateOneGql } = useMemo(() => {
    const fields = config ? getFieldsFromSurveys([config]) : { surveyFields: [], excludedFields: [] };

    return {
      excludedFields: fields.excludedFields,
      surveyConfig: config && isAdmin ? prepareAdminConfig(config) : config,
      applicationGetOneGql: applicationGetOne([...fields.surveyFields, ...(withFlags ? ['flags'] : [])]),
      applicationUpdateOneGql: applicationUpdateOne(
        isAdmin ? [...fields.surveyFields, 'stripErrorFields'] : fields.surveyFields,
      ),
    };
  }, [config, withFlags]);

  const { data, loading } = useQuery<{ applicationGetOne: Application }>(applicationGetOneGql, {
    variables: { id },
  });

  const isSurveyVisible = useMemo(() => {
    if (surveyConfig && data?.applicationGetOne) {
      return isVisible(surveyConfig, data?.applicationGetOne);
    }

    return true;
  }, [surveyConfig, data?.applicationGetOne]);

  const result = useMemo(() => {
    if (data?.applicationGetOne && config?.name) {
      const { flags = {}, ...initialValues } = data.applicationGetOne;

      return { flags: flags[config.name] || {}, initialValues: { ...initialValues, isAdmin } };
    }

    return { initialValues: undefined, flags: undefined };
  }, [data?.applicationGetOne, config?.name, isAdmin]);

  const [updateApplication, { loading: isUpdateApplicationLoading }] = useMutation<{
    applicationUpdateOne: Application;
  }>(applicationUpdateOneGql, {
    fetchPolicy: 'no-cache',
    onCompleted: onUpdateSuccess,
  });

  const onSubmit = useCallback(
    async (input: Partial<Application>, helpers: FormikHelpers<Partial<Application>>) => {
      try {
        await updateApplication({
          variables: {
            input: omitDeep(isAdmin ? input : { enableStripErrorFields: true, ...input }, excludedFields, ['avatar']),
          },
        });
      } catch (e) {
        const errors = extractValidationErrors(e as ApolloError);

        if (Object.keys(errors).length > 0) {
          Object.entries(errors).forEach(([name, message]) => {
            helpers.setFieldError(name, message);
          });
        } else {
          error((e as ApolloError).message || t('general.error.somethingWentWrong'));
        }
      }
    },
    [t, excludedFields, isAdmin],
  );

  return {
    surveyConfig,
    onSubmit,
    isUpdateApplicationLoading,
    isSurveyVisible,
    ...result,
    isGetApplicationLoading: loading,
  };
};
